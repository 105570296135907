$(function(){
	$("#light-btn").on('click',function(){
		$("body").toggleClass("light")
		Cookies.set('light', $("body").hasClass("light"), { expires: 365, path: '/' });
		console.log(Cookies.get('light'))
	})
	if(Cookies.get('light') == "true"){
		$("body").addClass("light")
	}else{
		$("body").removeClass("light")
	}

	var home_marquee_width = $("#home-marquee div").width()
	homeMarquee()
	function homeMarquee() {
		$("#home-marquee").offset({left: 0 })
		$("#home-marquee div").animate({
			left: `-=${home_marquee_width/2}px`,
		}, 25000, "linear", function() {
			$("#home-marquee div").css("left",0)
    	homeMarquee()
    });
  }

  // 移到網頁最上
  $(".btn-top").click(function() {
  	$("html, body").animate({ scrollTop: 0 }, 500);
  });

  // home大字fade out
  set_home_title()
  $(window).on('scroll',function(e){
  	set_home_title()
  })
  function set_home_title(){
  	var scroll_position = $("#home-designs").offset().top-$(window).scrollTop()+1000
  	if(scroll_position >= 0 && scroll_position <= 300){
  		$("#home-page #home-title-fixed").css("opacity",scroll_position/300)
  	}else if(scroll_position < 0){
  		$("#home-page #home-title-fixed").css("opacity",0)
  	}else{
  		$("#home-page #home-title-fixed").css("opacity",1)
  	}
  }
  
})